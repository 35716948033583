/**
 * @name   AppParent.js
 * @author Aud#9488
 *         This file contains the main component that'll host all of the child
 *         components that make up the main app screen.
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//App Stylings
import "../../Stylings/AppParent.css"

//Import App Components
import {ServerOutput}  from "./ServerOuputComponents/ServerOutput.js";
import {WorkshopInput} from "./InputComponents/WorkshopInput.js";

import {ConfigurationModal} from "./InputComponents/ConfigurationModal.js"

//Insights upload library
import createUpload from "@insights-gaming/uploader";


/**
 * @name AppComponent
 *       This is the component that houses all of the components 
 *       related to the use of this system
 */


export class AppComponent extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Setup Toast system
        this.AddToast = props.AddToast;

        //Setup date
        let Today = new Date();

        //Setup states
        this.state = {
            //For network loading like parsations
            "Loading"      : false,

            //For showing our configuration
            "ShowConfiguration" : false,

            //For files
            "CurrentStage"  : null,
            "UploadState"   : "No files have been uploaded... start uploading to get started :D",
            "AnalysisState"   : null,
            "Files" : [],
            "UploadedFiles" : [],
            "FilesProcessed" : false,

            //For showing information about the inputted workshop text
            "AnalysisId"       : "",
            "ActiveAnalysisId" : "",
            "WorkshopText"     : "",
            "Analyzing"        : false,
            "FetchingDownloadlinks" : false,
            "DownloadLinks"    : {"FightsUrl" : null, "EventsUrl" : null},
            "WorkshopErrors"   : {"Success" : false, "Message" : "No workshop logs detected, add some :D"},
          
            //For showing information about the outputted workshop data
            "WorkshopCSV"  : "",
            "EventsCSV"    : "",

            //For showing the data about the parsed workshop input 
            "PlayerList"        : [],

            //For the setting reguarding what we'll be sending off to the parser
            "Settings"     : {
                "GameName"          : `Our First Scrim`,
                "GameDate"          : `${Today.getFullYear()}-${Today.getMonth() + 1}-${Today.getDate()}`,
                "FightThrehsold"    : 10,
                "FetchEvents"       : false,
                "IncludeHeader"     : true,
                "YourTeam"          : "Insights.gg Valorant Squad",
                "TheirTeam"         : "Insights.gg Apex Squad",
                "YourRoster"        : [],
                "TheirRoster"       : []
            }
        }

    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        return(
            <div className={this.props.className} id="AppBody">
                {/* Configuration */}
                <div>
                    <ConfigurationModal
                        Settings         = {this.state.Settings}
                        PlayerList       = {this.state.PlayerList}
                        ModifyPlayerList = {(List) => {this.setState({"PlayerList" : List})}}
                        ModifySettings   = {(Setting, Value) => {this.ModifySettings(Setting, Value)}}
                        Show             = {this.state.ShowConfiguration}
                        SetVisibility    = {() => {this.setState({"ShowConfiguration" : !this.state.ShowConfiguration})}}
                        SendRequest      = {() => {this.ParseWorkshop()}}
                    />
                </div>

                {/* Parsation */}
                <div className="ParsationPane">
                    <WorkshopInput 
                        className          = "WorkshopInput"
                        Loading            = {this.state.Loading}   

                        CurrentStage       = {this.state.CurrentStage}
                        SetCurrentStage    = {(Stage) => {this.setState({"CurrentStage" : Stage})}}

                        EditUploadFiles    = {(Files) => {this.setState({"Files" : Files})}}
                        Files              = {this.state.Files}
                        UpdateFiles        = {(FileId, Metadata) => this.UpdateFile(FileId, Metadata)}
                        UploadFiles        = {() => {this.UploadFiles()}}
                        UpdateProcessed    = {() => {this.setState({"FilesProcessed" : !this.state.FilesProcessed})}}
                        ShowConfiguration  = {() => {this.setState({"ShowConfiguration" : !this.state.ShowConfiguration})}}

                        ModifySettings     = {(Setting, Value) => {this.ModifySettings(Setting, Value)}}
                        SetPlayers         = {(PlayerList) => {this.setState({"PlayerList" : PlayerList})}}
                        UploadState        = {this.state.UploadState}
                        AnalysisState      = {this.state.AnalysisState}
                        SetActiveState     = {(State) => {this.setState({"UploadState" : State})}}
                        WorkshopErrors     = {this.state.WorkshopErrors}
                        SetParseError      = {(Error) => {this.setState({"WorkshopErrors" : Error})}}
                    />

                    <ServerOutput
                        className   = "ServerOutput"
                        
                        CurrentStage = {this.state.CurrentStage}
                        UploadState        = {this.state.UploadState}
                        AnalysisState      = {this.state.AnalysisState}

                        FightsUrl   = {this.state.DownloadLinks.FightsUrl}
                        EventsUrl   = {this.state.DownloadLinks.EventsUrl}
                      
                        Fights      = {this.state.WorkshopCSV}
                        Events      = {this.state.EventsCSV}
                        GameDate    = {this.state.Settings.GameDate}
                        AddToast    = {this.props.AddToast}
                    />

                </div>   
                
            </div>

        )

    }



    /**
     * INTERAL APP FUNCTIONS
     */

    
    /**
     * @name ModifySettings
     * @description Saves the settings setup in the ConversionSettingsBox
     *              components, so that the conversion system may access 
     *              them easily
     * 
     * @param {JSON} Settings A json object that contains all of the settings we need
     */
    ModifySettings(SettingName, Value){
        //Fetch our settings
        let CurrentSettings = this.state.Settings;

        //Apply CurrentSettings
        CurrentSettings[SettingName] = Value;

        this.setState({"Settings" : CurrentSettings});
    }

    /**
     * @name UploadFiles
     * @description This function is fired when the user clicks the upload button :D
     *              It creates an analysis block to upload logs to, then uploads those logs
     * 
     */
    async UploadFiles(){
        //Set loading 
        this.setState({"Loading" : true, "CurrentStage" : "Upload", "UploadState" : "Creating Scrimblock..."})

        //First fetch the analysis block
        let AnalysisCreation = await fetch("/generate_analysis_block", {method: "POST"});

        //If the analysis block creation crashed in runtime
        if(AnalysisCreation.ok !== true){
            //Fetch errors and display them 
            console.error(AnalysisCreation.resposne);

            this.setState({"UploadState" : "Upload Failed during analysis block creation", "WorkshopErrors" : {"Success" : false, "Message" : "Server crashed when creating your analysis block"}, "Loading" : false});
            return;
        }

        //IF the analysis block creation had an error
        let AnalysisCreationJson = await AnalysisCreation.json();

        if(AnalysisCreationJson.Success !== true){
            //Fetch errors and display them 
            console.error(AnalysisCreationJson);

            this.setState({"UploadState" : "Upload Failed.", "WorkshopErrors" : {"Success" : false, "Message" : "Server had an error when creating your analysis block, said: " + AnalysisCreationJson.Message}, "Loading" : false});
            return;            
        }

        //Analysis Block Creation was good, say so and move on 
        this.setState({"UploadState" : "Successfully created analysis block, now uploading your logs to that block", "AnalysisId" : AnalysisCreationJson.Data})
        let AnalysisId = AnalysisCreationJson.Data;

        //Fetch files
        let Files = this.state.Files;

        //For each file, generate file upload, then upload it :D 
        for(var FileInt = 0; FileInt < Files.length; FileInt++){
            let FileBody = Files[FileInt];
            let Filename = FileBody.name;

            //Say that we're uploading this file...
            this.setState({"UploadState" : `Finding a place to upload file ${Filename}...`})

            //Generate upload url 
            let UploadUrl = await fetch("/generate_log_upload", {
                method: "POST",
                body: JSON.stringify({
                    "AnalysisId" : AnalysisId,
                    "Filename"   : Filename
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            //REQUEST DOESN"T HAVE A BODY

            //Check the upload generation 
            if(UploadUrl.ok !== true){
                //Fetch errors and display them 
                console.error(UploadUrl.resposne);

                this.setState({"UploadState" : "Upload Failed during link generation.", "WorkshopErrors" : {"Success" : false, "Message" : "Server crashed when uploading your file..."}, "Loading" : false});
                return;
            }

            //Check resposne
            let UploadUrlJson = await UploadUrl.json();

            //If the creation failed
            if (UploadUrlJson.Success === false){
                //Fetch errors and display them 
                console.error(JSON.stringify(UploadUrlJson));

                this.setState({"UploadState" : "Upload Failed during link generation", "WorkshopErrors" : {"Success" : false, "Message" : "Server crashed when uploading your file..."}, "Loading" : false});
                return;
            }

            //Everything else worked, let user know we're uploading 
            this.setState({"UploadState" : `Place found, uploading  ${Filename} now...`})
            

            //Link generation didn't fail, fetch it and upload it 
            let Link  = UploadUrlJson.Data.Url;
            let LogId = UploadUrlJson.Data.LogId;

            //Setup upload
            let UploadOptions = {
                size: FileBody.size,
                name: FileBody.name,
                type: "File",
                contents: FileBody
            }

            //Originally we did an array assigment but we stopped because we don't need index 0, and it makes no sense to have a variable dedicated to that
            let UploadCreated = await createUpload(Link, UploadOptions)
            let Uploader      = UploadCreated[1]

            //Start
            Uploader.start()

            //Catch changes in state
            for await (const State of Uploader){
                //If position == length we're fine
                if(State.position === State.length){
                    break;
                }

                else{
                    this.setState({"UploadState" : `Uploaded ${Math.floor(State.position / State.length)}% of ${Filename}`})
                }

            }

            //Upload done || Add it to uploaded
            let OldFiles = this.state.Files;
            let NewFiles = [];

            //Find file
            for(var OldFileInts = 0; OldFileInts < OldFiles.length; OldFileInts++){
                let Obj = OldFiles[OldFileInts]

                if(OldFileInts === FileInt){
                    Obj.AnalysisId = AnalysisId;
                    Obj.LogId      = LogId;
                    Obj.Uploaded   = true;

                }


                NewFiles.push(Obj);
            }
         
            this.setState({"UploadState" : `Done uploading ${Filename}`, "Files" : NewFiles});            

            
        }

        this.setState({"CurrentStage" : "UploadFinished"});            


    }


    /**
     * Updates a file in this.state.Files
     * @param {*} Id | The file id
     * @param {*} Metadata | The data we're updating
     */
    UpdateFile(Id, Metadata){
        //Find file
        let FileList = [];

        for(var FileInt in this.state.Files){
            let Obj = this.state.Files[FileInt]
            if(Id === FileInt){
                Obj["ProcessData"] = Metadata
            }

            FileList.push(Obj)
        }


        this.setState({"Files" : FileList})
    }


    async ParseWorkshop(){
        //Say we're loading
        this.setState({"Analzying" : true, "CurrentStage" : "Analysis", "AnalysisState" : "Starting analysis..."});

        //Fetch Workshop log
        let DataToSend = {
            "AnalysisId"    : this.state.AnalysisId,
            "WorkshopText"  : this.state.WorkshopText,
            
            "GameDate"      : this.state.Settings.GameDate,
            "GameName"      : this.state.Settings.GameName,

            "YourTeam"      :this.state.Settings.YourTeam,
            "TheirTeam"     : this.state.Settings.TheirTeam,

            "YourRoster"    : this.state.Settings.YourRoster,
            "TheirRoster"   : this.state.Settings.TheirRoster,

            "AttachHeader"  : this.state.Settings.IncludeHeader

        }

        //Send request
        let ParseRequest = await fetch("/start_analysis", {
            method: "POST",
            body: JSON.stringify(DataToSend),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        
        //Say we're done loading
        this.setState({"Loading" : false});
        
        //Check request for failure
        if(ParseRequest.ok !== true){
            this.AddToast("Server had an issue parsing your request- aka you managed to crash it GR",  {appearance: 'error', autoDismiss: false})
            
            return;
        }   

        //Further check
        let Data = await ParseRequest.json();

        if (Data.Success !== true){
            this.AddToast(`Ok we didn't crash but we couldn't handle your input- Server said: ${Data["Message"]}`,  {appearance: 'error', autoDismiss: false})
            
            console.log("INTERNAL ERROR")
            console.log(Data)

            return;
        }

        //Get id
        this.setState({"ActiveAnalysisId" : Data.Data});

        //Now check its status
        let Interval = setInterval(
            () => {this.PerformStatusCheck()},
            500 // Every 500 ms make request
        )

        //Save internal
        this.setState({"Interval" : Interval});

    }


    async PerformStatusCheck(){
        let StatusCheck = await fetch("/get_analysis_status", {
            method: "POST",
            body: JSON.stringify({
                "AnalysisId" : this.state.AnalysisId,
                "Id"         : this.state.ActiveAnalysisId
            }),
            headers: {
                "Cache-Control": "no-cache", "Pragma": "no-cache",
                'Content-Type': 'application/json'
            }
        });


        if(StatusCheck.ok !== true){
            //Fetch errors and display them 
            console.error(`Failed when analzying ${this.state.ActiveAnalysisId} for ${this.state.AnalysisId}`)
            console.error(StatusCheck.resposne);

            
            window.clearInterval(this.state.Interval)
            this.setState({"AnalysisState" : "Failed fetching the analysis information | Server crashed", "Analzying" : false})
            return
        }

        //Check resposne
        let StatusCheckJson = await StatusCheck.json();

        //If the creation failed
        if (StatusCheckJson.Success === false){
            //Fetch errors and display them 
            console.error(`Failed when analzying ${this.state.ActiveAnalysisId} for ${this.state.AnalysisId}`)
            console.error(JSON.stringify(StatusCheckJson));

            window.clearInterval(this.state.Interval)
            this.setState({"AnalysisState" : "Failed fetching the analysis information, check log", "Analzying" : false})
            return
        }


        //Succeeded, check status progress
        let Data = StatusCheckJson.Data;

        if(Data === undefined || Data.AnalysisProgress === undefined){
            this.setState({"AnalysisState" : "Still starting analysis..."})
            return
        }

        //If it it isn't done, return 
        if(Data.AnalysisProgress.Done === false){
            this.setState({"AnalysisState" : `Analysis has been started, analyzying map ${Data.AnalysisProgress.LogsDone.length} / ${Data.AnalysisProgress.LogsTodo.length}`})
            return
        }


        //If it is done but had errorrs
        if(Data.AnalysisProgress.Done === true && Data.AnalysisProgress.Failed === true){
            //Fetch errors and display them 
            console.error(`Failed when analzying ${this.state.ActiveAnalysisId} for ${this.state.AnalysisId}`)
            console.error(JSON.stringify(StatusCheckJson));

            window.clearInterval(this.state.Interval)
            this.setState({"AnalysisState" : `Failed analzying your file, check log and bug aud- Analysis ID ${this.state.AnalysisId}, active id ${this.state.ActiveAnalysisId}`, "Analzying" : false})
            return
        }


        //Fetch errors and display them 
        window.clearInterval(this.state.Interval)

        //If we're already fetching links, exit out
        if(this.state.FetchingDownloadlinks === true){
            return
        }

        else{
            this.setState({"AnalysisState" : "Analysis finished, fetching it", "FetchingDownloadlinks" : true})


            this.FetchAnalysisDownloadLinks();
        }

    }



    async FetchAnalysisDownloadLinks(){
        //Fetch
        let StatusCheck = await fetch("/get_analyis_download_link", {
            method: "POST",
            body: JSON.stringify({
                "AnalysisId" : this.state.AnalysisId,
                "Id"         : this.state.ActiveAnalysisId
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });


        if(StatusCheck.ok !== true){
            //Fetch errors and display them 
            console.error(`Failed fetching download links on ${this.state.ActiveAnalysisId} for ${this.state.AnalysisId}`)
            console.error(StatusCheck.resposne);

            this.setState({"AnalysisState" : "Failed fetching download links | Server crashed", "Analzying" : false})
            return
        }

        //Check resposne
        let StatusCheckJson = await StatusCheck.json();

        //If the creation failed
        if (StatusCheckJson.Success === false){
            //Fetch errors and display them 
            console.error(`Failed fetching download links on  ${this.state.ActiveAnalysisId} for ${this.state.AnalysisId}`)
            console.error(JSON.stringify(StatusCheckJson));

            this.setState({"AnalysisState" : "Failed fetching download links , check log", "Analzying" : false})
            return
        }


        //Succeeded, check status progress
        let Data = StatusCheckJson.Data;

        this.setState({"DownloadLinks" : {"FightsUrl" : Data.FightsUrl, "EventsUrl" : Data.EventsUrl}, "CurrentStage" : "AnalysisFinished", "Analzying" : false, "FetchingDownloadlinks" : false})
    }

}