/**
 * @name   Tab.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll act as the tab clicker 
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';


/**
 * @name OutputTab
 *       This is the component the basic tab button
 */


export class OutputTab extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Save State
        this.state = {
            "Activated" : props.Activated
        }

    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        let TabStyle = {
            height : "100%",
            width  : "auto",
            minWidth: "10%",
            textAlign: "center",
            paddingLeft: "1%",
            paddingRight: "1%",
            paddingBottom: "0.5%",
            borderBottom : "",
            marginBottom: "-2px"

        }

        if(this.props.Page === this.props.Name){
            TabStyle.borderLeft  = "1px #3E454D solid"
            TabStyle.borderRight  = "1px #3E454D solid"
            TabStyle.borderBottom = "#3E454D"
            TabStyle.background = "#3E454D"
            
        }

        //Render
        return(
            <div onClick={() => {this.props.ChangePage(this.props.Name);}} style={TabStyle}>
                <div style={{margin: "auto"}}>
                    {this.props.Name}
                </div>
            </div>
        )

    }



}