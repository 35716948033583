/**
 * @name   LoginParent.js
 * @author Aud#9488
 *         This file contains the main component that'll host all of the child
 *         components that make up the Login Screen.
 */


//Import React Components
import React from 'react';




/**
 * @name LoginComponent
 *       This is the component that houses all of the components 
 *       related to logging into the system
 */


export class LoginComponent extends React.Component{

    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        return(
            <div>Login Component</div>
        )
    }
}