/**
 * @name   SettingsComponent.js
 * @author Aud#9488
 *         This file contains the list of settings
 *         that will be displayed in the configuration 
 *         panel of the main app
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormCheck} from "react-bootstrap";

//Our Styling
import "../../../../Stylings/InputComponents/Settings.css";


/**
 * @name Settings
 *       This is the component that houses all of 
 *       the settings related to conversion
 */


export class SettingsList extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props;
        
        //Fetch Save functions
        this.ModifySettings = props.ModifySettings;

        //Save Settings
        this.Settings = props.Settings;


    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render
        return(
            <div className="SettingsComponent">
                <h3 className="TitleOfSettingsBox"> Settings </h3>
                {/* CSV Header */}
                <div className = "SettingsBox">
                    <h6 className = "SettingsTitle">Attach CSV header</h6>

                    <div className="SettingsDescriptionBox">
                        <div className="SettingsDescription">
                            Checking this allows you to remove the usually included header row on 
                            your CSV
                        </div>

                        <FormCheck className="SettingsSwitch" custom type="switch">
                            <FormCheck.Input  checked={this.Settings.IncludeHeader}/>
                            <FormCheck.Label  onClick={() => {this.Settings.IncludeHeader = !this.Settings.IncludeHeader; this.ModifySettings("IncludeHeader", this.Settings.IncludeHeader)} }></FormCheck.Label>
                        </FormCheck>
                    </div>


                </div>

                {/* Event List */}
                <div className = "SettingsBox">
                    <h6 className = "SettingsTitle">Fetch Event List CSV as well</h6>

                    <div className="SettingsDescriptionBox">
                        <div className="SettingsDescription">
                            Checking this allows you to fetch the list of events
                            pulled from your workshop codes. Events such as Kills,
                            Deaths, ults. It compiles them into a CSV for you to 
                            download and use just like you would the normal CSV
                        </div>
                        <FormCheck className="SettingsSwitch" custom type="switch">
                            <FormCheck.Input  checked={this.Settings.FetchEvents}/>
                            <FormCheck.Label  onClick={() => {this.Settings.FetchEvents = !this.Settings.FetchEvents; this.ModifySettings("FetchEvents", this.Settings.FetchEvents)} }></FormCheck.Label>
                        </FormCheck>
                    </div>


                </div>
                {/**
                 * OLD FIGHT THRESHOLD DATA
                    <div className = "SettingsBox">
                    <h6 className = "SettingsTitle">Set the fight threshold</h6>

                    <div className="SettingsDescriptionBox">
                        <div className="SettingsDescription">
                            We define Fights as groups of events (kills, deaths, ults) that occur close together during a period of time. In order to group these events, we look 
                            at all the events that occured within the current map of the scrim, and find periods of time where in which events aren't occuring. These periods of time are obviously when fights
                            aren't occuring so we can actually define fight ends and fight starts here. This setting changes the maximum time in seconds between events allowed before we define the start
                            of a new fight. The default is 10 seconds.
                        </div>
                        <FormControl
                            className   = "SettingsNumberInput"
                            placeholder = "10"
                            onChange    = {(Target) => {this.props.ModifySettings("FightThreshold", Target.target.value)}}
                        />
                    </div>

                    </div>

                **/}



            </div>
        )
    }


}