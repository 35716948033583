/**
 * @name   IndexItem.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll act as a button for navigating the 
 *         table of contents 
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Styling
import "../../../Stylings/AboutComponents/About.css";

/**
 * @name IndexItem
 *       This is the component the about container 
 *       that'll contain all of the info about...
 *       well this
 */


export class IndexItem extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Save State
        this.state = {
            "CurrentPage" : this.props.CurrentPage
        }

    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //If the item is active, show it in the form of styling
        let ClassName = "UnActiveListItem";
        if(this.props.CurrentPage === this.props.Page){
            ClassName = "ActiveListItem";
        }

        //Render
        return(
            <div className={ClassName} style={this.props.style} onClick={() => {this.props.ChangePage(this.props.Page)}}>
                {this.props.Text}
            </div>
        )

    }



}