/**
 * @name   GameDisplayComponent.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll host the Game Information box 
 *         that displays on the Server output 
 *         component
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import {Navbar} from "react-bootstrap";

//Sub Components
import {OutputTab} from "../GeneralComponents/Tab.js"

//For the editor
import { SheetsDisplay } from './SheetDisplay.js';

//Styling
import "../../../Stylings/OutputComponents/GameDataDisplay.css";

/**
 * @name GameDataDisplay
 *       This is the component that houses all of the 
 *       components related controlling the settings 
 *       of the workshop conversion 
 */


export class GameDataDisplay extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Fetch Save functions
        this.state = {
            "Page" : "Fights",
            "Grids" : {
                "Fights" : [[]],
                "Events" : [[]]
            }
        }

    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Setup display
        let Display = [];

        //If we don't have data, tell the display to show no data error
        if(this.props.FightsCSV.length === 0){
            Display.push(this.ReturnNoData());
        }


        //If we do have data, tell display to show
        else{
            Display.push(this.ReturnData());
        }

        //Render
        return(
            <div className="DisplayParent">
                <Navbar className="DisplayNavBar">
                    <OutputTab
                        Name="Fights"
                        Page={this.state.Page}
                        ChangePage={(Page) => {this.setState({"Page" : Page}); this.props.ChangePage(Page);}}
                    />
                    <OutputTab
                        Name="Events"
                        Page={this.state.Page}
                        ChangePage={(Page) => {this.setState({"Page" : Page}); this.props.ChangePage(Page);}}
                    />
                </Navbar>   


                <div className="DataDisplayDiv">
                    {Display}
                </div>


            </div>
        )

    }


    /**
     * @name ReturnNoData
     * @description Returns a div that explains to the user that they need to input workshop logs in order 
     *              to get started. Is called when no workshop logs are present // parsed
     * 
     * @return {React.Component} A div explaining that now logs have been parsed
     */
    ReturnNoData(){
        return(
            <div style={{width: "100%", height: "50%", textAlign: "center", marginTop: "12.5%"}}>
                Parse some workshop logs to get started :D
            </div>
        )
    }


    /**
     * @name ReturnData
     * @description Returns the SheetDisplay when we have a parsed log present. 
     *              
     * 
     * @return {SheetsDisplay} a component that displays the data in a google spreadsheets like format
     */
    ReturnData(){
        //Select CSV
        let CSV = "";

        //TODO: Compress this
        if(this.state.Page === "Fights"){
            CSV = this.props.FightsCSV;
        }

        if(this.state.Page === "Events"){
            CSV = this.props.EventsCSV;
        }

        return(
            <SheetsDisplay
                style={{width: "100%", height: "100%"}}
                CSV = {CSV}
                UpdateGridData = {(GridData) => {this.props.UpdateGridData(GridData);}}
            />
        )
    }


}