//Bring in the react components
import React from 'react';
import ReactDOM from 'react-dom';

//Import our actual app
import App from './App';

//Display it for the user
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


