/**
 * @name   ApiComponent.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll store all of the info that 
 *         tells the user how to program with this
 *         product.
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Sub Components
import {ApiText} from "./ApiText.js";

//Styling
import "../../../Stylings/AboutComponents/About.css";

/**
 * @name Api
 *       This is the component the about container 
 *       that'll contain all of the info about the API
 */


export class Api extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Save State
        this.state = {
            "Page" : "About",
            "CheckingScroll" : false,
            "Pages" : {
                "About"                 : "IntroSection",
                "The Api Documentation" : "TheApi",
                "/Convert_To_CSV"       : "Convert_To_CSV"
            }
        }

    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render
        return(
            <div className={this.props.className} id="AppBody">
                <div className="AboutParent" id="AboutParent">
                    <div className="MainColumn">
                        <div className="LeftColumn">
                            <ApiText className="InfoTextDiv"/>
                        </div>

                        <div className="RightColumn">
                            <div className="CardItemsParent">                     
                                <div className="CardItems">

                                    <div className="CardHolder">
                                    
                                        {/* Lobby Codes */}
                                        <div className="IndexCard LobbyCode">
                                            <div className="IndexBody">
                                                <div className="CardHeaderText">Lobby Codes</div>

                                                <hr className="IndexLineBreak"/>
                            
                                                {/* Prod */}
                                                <div className="CodeContainer">
                                                    <div className="CodeTitle">[LIVE] Converted Seita Lobby</div>
                                                    <div className="Code">KVKR7</div>
                                                </div>
                                            
                                                <div className="CodeContainer">
                                                    <div className="CodeTitle">[LIVE] Official Games Version</div>
                                                    <div className="Code">T1PHB</div>
                                                </div>

                                                {/* PTR */}
                                                <div className="CodeContainer">
                                                    <div className="CodeTitle">[PTR] Converted Seita Lobby</div>
                                                    <div className="Code">Z6XEJ</div>
                                                </div>
                                            
                                                <div className="CodeContainer">
                                                    <div className="CodeTitle">[PTR] Official Games Version</div>
                                                    <div className="Code">A7Y9N</div>
                                                </div>


                                                {/* OPR */}
                                                <div className="CodeContainer">
                                                    <div className="CodeTitle">[OPR] Converted Seita Lobby</div>
                                                    <div className="Code">--</div>
                                                </div>
                                            
                                                <div className="CodeContainer">
                                                    <div className="CodeTitle">[OPR] Official Games Version</div>
                                                    <div className="Code">--</div>
                                                </div>
                                            
                                            
                                            </div>
                                        </div>
                        
                                        {/* Links */}
                                        <div className="IndexCard OtherLinks">                
                                            <div className="IndexBody">
                                                <div className="CardHeaderText">Important Links</div>
                                                
                                                <hr className="IndexLineBreak"/>
                                                
                                                
                                                <div className="LinkSection">

     
                                                            <a className="Links" href="https://overwatchleague.com/en-us/news/22919644/what-is-a-teamfight" target="_blank" rel="noreferrer">How OWL defines a fight</a>
                                                            <br/>

                                                            <a className="Links" href="https://drive.google.com/file/d/1zAmL28x3DrLxRgMdz6xV6tPxqSdVoUR7/view?usp=sharing" target="_blank" rel="noreferrer">Example Log</a>
                                                            <br/>

                                                            <a className="Links" href="https://drive.google.com/file/d/1a5N2Y1yyS-Z5f6D7-Tj2QeS9f24SCqbi/view?usp=sharing" target="_blank" rel="noreferrer">Example Fights output</a>
                                                            <br/>

                                                            <a className="Links" href="https://drive.google.com/file/d/1snt_CrZ-u-4k5qBS9PMV-gWjKGF_n1Fn/view?usp=sharing" target="_blank" rel="noreferrer">Example Events output</a>
                                                </div>
                                            </div>

                                    </div>
                                    
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )

    }

    ChangePage(Page){
        //Change the page state
        this.setState({"Page" : Page, "CheckingScroll" : true});

        //Figure out what page we're on 
        for(var PageInts in this.state.Pages){
            let ThisPage = this.state.Pages[PageInts];

            if(PageInts === Page){
                //Scroll
                document.getElementById(ThisPage).scrollIntoView({behavior:"smooth"});

                //Wait until scroll done
                async function CheckForFinish(Function, Elem, Callback){
                    let Sleep = (ms)  => {
                        return new Promise(resolve => setTimeout(resolve, ms));
                    }
   
                    while(Function(document.getElementById(Elem))  !== true){
                        await Sleep(500);
                    }

                    Callback();
                }
                  
                //Execute that wait
                CheckForFinish(this.CheckElemPos, ThisPage, () => {this.setState({"CheckingScroll" : false})})
   
                //Exit for loop to save proccess
                break;
            }

        }





    }

    //Setup scroll event that find what page we're on
    componentDidMount(){
        //Setup event
        document.getElementById("AboutParent").onscroll = (e) => {
            //If we're checking already ignore
            if(this.state.CheckingScroll === true) return;

            //Say we're looking
            this.setState({"CheckingScroll" : true});
            
            //Get elems in view
            let ActivePage = this.state.Pages["About"];

            for(let Pages in this.state.Pages){
                //Check to see if it's in view
                let Check = this.CheckElemPos(document.getElementById(this.state.Pages[Pages]));

                //Special call for about, so it's always active if visible
                if(Pages === "About" && Check === true){
                    ActivePage = Pages;
                    break;
                }

                //If the check was true set the page as active
                if(Check === true){
                    ActivePage = Pages
                }


            }

            //Done Looking 
            this.setState({"Page" : ActivePage, "CheckingScroll" : false});
        }


    }

    //Remove scroll event
    componentWillUnmount(){
        document.getElementById("AboutParent").onscroll = null;
    }


    CheckElemPos(Elem){
        Elem = Elem.getBoundingClientRect()

        let Top    = Elem.top;
        let Left   = Elem.left;
        let Width  = Elem.width;
        let Height = Elem.height - 200;
      

        return (
            Top < (window.pageYOffset + window.innerHeight) && Left < (window.pageXOffset + window.innerWidth)
            &&
            (Top + Height) > window.pageYOffset && (Left + Width) > window.pageXOffset
        );

    }

}