/**
 * @name   WorkshopInput.js
 * @author Aud#9488
 *         This file contains the main component that'll host all of the child
 *         components that make up workshop input component.
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card, Button} from "react-bootstrap";

//For showing files
import {UploadedFile} from "./FileComponent.js";


//For styling
import "../../../Stylings/InputComponents/WorkshopInput.css"


/**
 * @name WorkshopInput
 *       This is the component that houses all of the components 
 *       related to getting the input of the user
 */


export class WorkshopInput extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);   
        
        //Props
        this.props = props

        //State
        this.state = {
            "FileList"   : {},
            "PlayerList" : null
        }
    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Return the data
        return(
            <Card 
                className={this.props.className} 
                
                onDragEnter = { (e) => {e.preventDefault(); e.stopPropagation()}}
                onDragOver  = { (e) => {e.preventDefault(); e.stopPropagation()}}
                onDragLeave = { (e) => {e.preventDefault(); e.stopPropagation()}}
                onDrop      = { (Data) => {Data.preventDefault(); Data.stopPropagation();this.OnWorkshopEntry(Data.dataTransfer.files)}}
                
                
                >
                <Card.Header className="InputCardTitle">
                    <div className="InputCardTitleText">Workshop Input</div>
                </Card.Header>
            
                <Card.Body className="InputCardBody">
                    <div  className="InputCardUploadBody">
                        {this.UploadDialog()}

                        {this.GenerateUploadFiles()}
              
                        <div className="InputCardButtons">
                            {this.AnalyzeButton()}
                        </div>
                    </div>
                
                </Card.Body>

                <Card.Footer style={{height: "62px"}}>
                </Card.Footer>
            </Card>
        

        )

    }

    /**
     * INTERNAL COMPONENT FUNCTIONS
     *  These render special components for display
     */

    UploadDialog(){
        //Exit out if not in upload
        if(this.props.CurrentStage !== null){
            return(<div></div>)
        }

        //If we're good
        return(
            <div>
                <h3 className="InputCardHeader">Upload Logs</h3> 

                <label for="log-upload" className="InputCardLogUpload">
                    Add logs to upload
                </label>

                <input 
                    type      = "file" 
                    id        = "log-upload"
                    className = "InputCardInputBody"
                    onChange  = {(Event) => {this.OnWorkshopEntry(Event.target.files)}}
                    multiple
                />
            </div>
        )
    }

    UploadButton(){
        //Exit out if the upload button isn't needed
        if(this.props.CurrentStage === null || this.props.CurrentStage.startsWith("Upload") || this.props.CurrentStage.startsWith("Analysis")){
            return(<div></div>)
        }

        //Figure out whether or not we need to show this button
        let ShowButton = true;

        if(this.props.Loading === true || this.props.WorkshopErrors.Success === true){
            ShowButton = false;
        }

        return(
            <div className="InputCardButtons">
                <Button 
                    variant   = "danger"
                    className = "InputCardUploadButton"
                    disabled  = {ShowButton} 
                    onClick   = {() => {this.props.SetCurrentStage(null)}}
                >
                    Reset
                </Button> 

                <Button 
                    variant   = "success"
                    className = "InputCardUploadButton"
                    disabled  = {ShowButton} 
                    onClick   = {this.props.UploadFiles}
                >
                    Upload
                </Button> 

            </div>
        )
    }

    AnalyzeButton(){
        //Exit out if they can't anaylze
        if(this.props.CurrentStage !== "UploadFinished"){
            return (<div></div>);
        }

        //Setup Show
        let HideButton = false;

        //Check to see if all of the files have been processed before they can be clicked
        for(var FileInts in this.props.Files){
            //Setup file
            let File = this.props.Files[FileInts]

            //If the file has had a proc return || Hasn't been processed || Had errors during process
            if( (File.ProcessData === undefined || File.ProcessData === null) || File.ProcessData.ProcessingInfo === undefined ||  File.ProcessData.ProcessingInfo.Processing === true || File.ProcessData.ProcessingInfo.ProcessingErrors !== null){
                HideButton = true;
            }
        }

        //If there are no files, show false
        if(this.props.Files === null || this.props.Files.length === 0){
            HideButton = true;
        }

        //If we can show analysis data, update the parent data
        if(HideButton === false){
            //Update
            this.UpdateAnalysisData();
        }

        //Return the analysis button
        return(
            <Button 
                variant   = "success"
                className = "InputCardUploadButton"
                disabled  = {HideButton} 
                onClick   = {this.props.ShowConfiguration}
            >
                Analyze
            </Button> 

        )
    }

    GenerateUploadFiles(){
        //If we're not in the upload state don't show this
        if(this.props.CurrentStage === null){
            return(<div></div>)
        }


        //Build uploaded files
        let FileList = [
            <hr style={{margin: 0}}/>
        ];

        
        //Gen File
        for(var FileInts in this.props.Files){
            FileList.push(
                <UploadedFile 
                    Id   = {FileInts}
                    File = {this.props.Files[FileInts]}
                    UpdateProcessData = {(Id, ProcessData) => {this.props.UpdateFiles(Id, ProcessData)}}
                />
            )

            FileList.push(
                <hr style={{margin: 0}}/>
            )
        }

        //FileList.pop()

        return(
            <div className="UploadedFilesContainer">

                <h2 style={{"textAlign": "left"}}>Logs Added</h2>

                <div className="FileComponentHeader">
                    <h5>File name</h5>
                    <h5>Processed?</h5>
                    <h5>Current Status</h5>
                </div>

                <div style={{maxHeight: "300px", overflowY: "auto", borderRadius: "calc(.25rem - 1px)", border: "1px solid rgba(0, 0, 0, 0.4)", backgroundColor: "#343a40"}}>
                    {FileList}
                </div>

                {this.UploadButton()}
            </div>
            
        )
    }


    /**
     * COMPONENT FUNCTIONS
     *  these just run code the components need
     */

    /**
     * @name OnWorkshopEntry
     * @description Fires when a input has been sent into the workshop 
     *              entry section. 
     */
    OnWorkshopEntry(Files){
        //Check to see if the given file(s) have a .log finish...
        //TODO

        let NewFiles = [];

        for(var FileInt = 0; FileInt < Files.length; FileInt++){
            let File = Files[FileInt];
            
            File.Filename    = File.name;
            File.AnalysisId  = null;
            File.LogId       = null;
            File.ProcessData = null;
            File.Uploaded    = false;

            NewFiles.push(File)
        }

        //Update the list of files
        this.props.EditUploadFiles(NewFiles);
        this.props.SetCurrentStage("PreUpload")
        this.props.SetParseError({"Success" : true, "Message" : ""});
        this.props.ModifySettings("Loading", false);
    }


    /**
     * @name UpdateAnalysisData
     * @description This updates the parent component's data structure with the new 
     *              info derrived from the uploaded file's processing info
     */
    UpdateAnalysisData(){
        let FileList = this.props.Files;

        //let MapsPlayed = [];
        let Players    = [];


        //For each file grab it's meta data and update our internals
        for(var FileInt in FileList){
            let File = FileList[FileInt].ProcessData.Metadata;

            //Find players
            for(var PlayerInts in File.Players){
                let Player = File.Players[PlayerInts];

                //Search through existing player list
                let Found = false;

                for(var FoundPlayers in Players){
                    if( Players[FoundPlayers] === Player){
                        Found = true;
                        break;
                    }
                }

                //If not found add to list
                if(Found !== true){
                    Players.push(Player)
                }

            }

        }

        //Before we push metadata, check to see if we haven't already

        //If there's no players, just add them
        if(this.state.PlayerList === null){
            this.props.SetPlayers(Players);
            this.props.SetActiveState("Done proccessing the vods. You may now analyze them!")
            this.setState({"PlayerList" : Players})
            return;

        }

        //Check to see if the meta data is the exact same
        let Different = false;
        for(var NewPlayers in Players){
            let Found = false;

            //Sort through old players and find new players
            for(var OldPlayers in this.state.PlayerList){
                if(this.state.PlayerList[OldPlayers] === Players[NewPlayers]){
                    Found = true;
                }
            }

            //If they weren't found, they're different and need to be updated
            if(Found === false){
                Different = true;
                break;
            }


        }


        //Push meta data to parent
        if(Different === true){
            this.props.SetPlayers(Players);
            this.props.SetActiveState("Done proccessing the vods. You may now analyze them!!");
            this.setState({"PlayerList" : Players})
            return;

        }

    }


}