/**
 * @name   IssueComponent.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll display issues that the dev team know of
 */


//Import React Components
import React from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap"

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';


/**
 * @name IssuesComponent
 *       This is the component the basic tab button
 */


export class IssuesComponent extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Save State
        this.state = {
            "FetchingIssues" : true,
            "Issues" : []
        }

    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //If we're fetching issues don't return anything
        if(this.state.FetchingIssues === true){
            return(<div></div>)
        }

        //If we finished fetching issues show
        if(this.state.FetchingIssues === false && this.state.Issues.length !== 0){
            //Render
            return(
                <OverlayTrigger
                    placement="right"
                    overlay={this.ReturnIssues()}
                    style={{float: "right"}}
                >
                    <svg width="auto" height="90%" viewBox="0 0 16 16" class="bi bi-exclamation-diamond" fill="#f07200">
                        <path fill-rule="evenodd"  d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                    </svg>
                </OverlayTrigger>
            )
        }
    }

    //After mount send a load request
    componentDidMount(){
        let IssueRequest = new XMLHttpRequest();
        IssueRequest.addEventListener("load", (Request) => {this.IssueRequestCallback(Request)});
        IssueRequest.open("GET", "/fetch_issue_list");
        IssueRequest.send();
    }


    /**
     * NETWORK RETURNS
     *         The following are functions that handle
     *         call backs from network requests
     */

    /**
     * @name        IssueRequestCallback
     * @description Handles the call back for the request to issues
     *  
     * @param       {XMLHttpRequest.Response} Request 
     */
    IssueRequestCallback(Request){
        //if there aren't any issues, show data
        if(Request.target.status === 200){
            let Data = JSON.parse(Request.target.response)
    
            this.setState({
                "FetchingIssues" : false,
                "Issues" : Data["Issues"]
            });

        }
    }


    /**
     * COMPONENT RETURNS
     *         The following are functions that return 
     *         custom component setups for renders
     */

    ReturnIssues(){
        //Create a list of issues
        let IssueList = [];
        for(var Issues in this.state.Issues){
            let Issue = this.state.Issues[Issues];

            IssueList.push(
                <div style={{marginBottom: "1em", color: "white"}}>
                    <h6>{Issue["Title"]}</h6>
                    <div>{Issue["Description"]}</div>
                </div>
            )


        }
        //Show those issues
        return(
            <Popover style={{maxWidth: "500px", backgroundColor: "#3E454D", borderColor: "#343a40", marginTop: "1em" }}>
                <Popover.Title
                    style={{
                        fontWeight: "500",
                        fontSize: "1.5em",
                        color: "white",
                        backgroundColor: "#343a40",
                        borderColor: "#3E454D" 
                    }}
                >
                    Currrent Issues on the platform
                </Popover.Title>
                
                <Popover.Content>
                    {IssueList}
                </Popover.Content>
            </Popover>

        )
    }


}