/**
 * @name   App.js
 * @author Aud#9488
 *         This file contains the main app component 
 */

//Bring in react
import React from 'react';

//React Bootstrap items
import {Navbar} from "react-bootstrap"

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

//Import our stylings
import "./Stylings/App.css";


//Bring in our root components
import {MenuBar}        from "./Components/AppComponents/MenuComponents/MenuBarComponent.js";
import {AppComponent}   from "./Components/AppComponents/AppParent.js";
import {About}          from "./Components/AppComponents/AboutComponents/AboutComponent.js";

import {LoginComponent} from "./Components/LoginComponents/LoginParent.js"; //TODO: DO something with this lmao

//Bring in third party libs
import {ToastProvider, useToasts} from 'react-toast-notifications'
import { Api } from './Components/AppComponents/ApiComponent/ApiComponent';



/**
 * @name App
*        This is the main component loaded into teh root of DOM
 */
export default class App extends React.Component{
  /**
   * Just Super props and setup states
   * 
   * @constructor
   * @param {React.props} props      Just your usual react props
   * 
   * @var   {React.state} this.state Contains whether we're logged in or not
   */
  constructor(props){
    //Super the props
    super(props);

    //Setup our state system
    this.state = {
      "LoggedIn" : true,

      "Page" : "App"
    }

    //Setup Toasts
    this.AddToast = null;

    //Title
    window.document.title = "Pro 2.0";

  }



  /**
   * Default React.Component functions
   * 
   * These are the default component functions we need
   */

  /**
   * @name componentDidMount
   *        When the component mounts on screen, 
   *        check to see if we can touch the API Server
   */
  componentDidMount(){
      //Check to see if we can even access the API server...
      let APITouch = new XMLHttpRequest();
      APITouch.addEventListener("load", (Request) => {this.APIConnectionCallBack(Request)});
      APITouch.open("GET", "/workshop_to_csv");
      APITouch.setRequestHeader("Content-Type", "application/json")
      APITouch.send();
    
  }

  /**
   * @name render
   *       Just renders the app onto the screen, loads the main app 
   *       component if they're logged in and loads a login screen
   *       if they're not logged in
   */
  render(){
    //Setup Toasts
    let CreateToasts = () => {
      //Create neat toast
      const {addToast} = useToasts();
      this.AddToast = addToast;
      
      return(<div></div>)
    }


    //Check login status
    if(this.state.LoggedIn === true){
      return(
        <Router>
          <ToastProvider placement="bottom-right">
            <div className="App">
              {/* Toasts */}
              <CreateToasts/>
              

                {/* Nav Bar */}
                <Navbar className="NavigationBar" fixed="top" bg="dark">
                    <Navbar.Brand>
                      <div className="NavBarText">
                        <div className="NavBarTitle">Workshop Farmer</div>
                        <div className="NavBarSpacer">|</div>
                        <div className="NavBarSubTitle">Beta</div>
                      </div>
                    </Navbar.Brand>

                </Navbar>
                
                <div className="AppFrame">
                  <Switch>
                    <Route path="/convert">
                      <MenuBar 
                        className  = "MenuBar"
                        Page       = {this.state.Page}
                        ChangePage = {(Page) => {this.setState({"Page" : Page})}}
                      />
                      <AppComponent 
                        className = "AppBody" 
                        AddToast  = {(Message, Style) => {this.AddToast(Message, Style)}}
                      />
                    </Route>

                    <Route path="/api">
                      <MenuBar 
                        className  = "MenuBar"
                        Page       = {this.state.Page}
                        ChangePage = {(Page) => {this.setState({"Page" : Page})}}
                      />
                      <Api
                        className = "AppBody"
                      />
                    </Route>

                    <Route path="/">
                      <MenuBar 
                        className  = "MenuBar"
                        Page       = {this.state.Page}
                        ChangePage = {(Page) => {this.setState({"Page" : Page})}}
                      />
                      <About
                        className = "AppBody"
                      />
                    </Route>


                  </Switch>
                  
                </div>
            
            </div>

          </ToastProvider>
        </Router>
        

      )
    }


    if(this.state.LoggedIn === false){
      return(<LoginComponent/>)
    }

  }



  CurrentAppPage(){
    //If we're on the app page load the app page
    if(this.state.Page === "App"){
      return (
        <AppComponent 
          className = "AppBody" 
          AddToast  = {(Message, Style) => {this.AddToast(Message, Style)}}
        />
      )
    }


    if(this.state.Page === "About"){
      return(
        <About
          className = "AppBody"
        />
      )
    }

    if(this.state.Page === "Api"){
      return(
        <Api
          className = "AppBody"
        />
      )
    }


    //A catch for any other page
    else{
      return(<div>Fuck</div>)
    }

  }


  /**
   * API Functions
   * 
   * These are functions that deal directly with the API
   */

  /**
   * @name APIConnectionCallBack
   * @description This is the call back that's fired when the web request to the API finishes
   * @param {XMLHttpRequest.response} Request - The return from the api
   */
  APIConnectionCallBack(Request){
    //Check the status
    let Status = Request.target.status;

    //App Failed to Connect
    if(Status !== 200){
      this.AddToast("Failed to connect to API Server, you may have issues using the site", {appearance: 'error', autoDismiss: false})      
    }
  }

}

