/**
 * @name   ApiText.js
 * @author Aud#9488
 *         This file contains the raw text
 *         that will be displayed to the user at the end of the day
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Styling
import "../../../Stylings/AboutComponents/About.css";

/**
 * @name ApiText
 *       This is the component that contains  
 *       some fucking text
 */


export class ApiText extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);
   
        //Props
        this.props = props;
    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render



        return(
            <div className={this.props.className}>
                <h1 className="TextDivTitle">The API</h1>

                <div>
                    Hi. Aud here- So sadly I didn't actually have enough time to really nail out this section like I wanted. But I do have a very broad 
                    understanding of how the API works (since I built it lol) and would be more than willing to help you write scripts to utalize the analysis 
                    features of this program without doing it by hand. 

                    <br/>

                    That being said I figure if you're on this page it means you're a developer and are very capable of using inspect element to look at the network 
                    tab and understand how these calls are being made :) Either way feel free to join the discord and ask plenty of questions until I've the time to 
                    write this section.
                </div>

        </div>


    )

    }


}