/**
 * @name   MenuBarComponent.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll host the menu the user will use
 *         to navigate the different pages 
**/

//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import {OverlayTrigger, Tooltip} from "react-bootstrap";

import {Link} from "react-router-dom";



import { IssuesComponent } from '../GeneralComponents/IssuesComponent.js';

//Stylings
import "../../../Stylings/MenuComponents/MenuBar.css"


/**
 * @name MenuBar
 *       This is the component that houses all of the 
 *       components related to setting up the final
 *       parse request
 */


export class MenuBar extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Setup States
        this.state = {
        }

    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     * 
     * NOTE FOR AUD LATER | We're putting the Raw SVGs in so we can edit their 
     * colour depending on what menu is active
     */
    render(){
        //Checks to see if the menu should be seen as an active button yet
        let AboutColour = "grey";
        let ApiColour   = "grey";
        let AppColour   = "grey";

        //Check
        if(window.location.pathname === "/"){
            AboutColour = "white";
        }


        if(window.location.pathname === "/convert"){
            AppColour = "white";
        }

        if(window.location.pathname === "/api"){
            ApiColour = "white";
        }


        //Return
        return(
            <div className={this.props.className}>
                {/* Insights Logo */}
                <div>
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip>
                                The Insights.gg Website
                            </Tooltip>
                        }
                    >
                        <div className="InsightsLogo" onClick={() => {window.open("https://insights.gg/about")}} title="Insights.gg">
                            <img className="InsightsPicture" src="/insights-logo.svg" alt="The insights logo"/>
                        </div>
                    </OverlayTrigger>
                </div>

                <hr className="MenuDivider"/>

                {/* App Section*/}
                <div className="MenuItemGroups">
                    {/* About Page */}
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip>
                                The about section, lol
                            </Tooltip>
                        }
                    >
                        <Link className="MenuButton" to="/">
                            <svg className="MenuIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={AboutColour} width="24px" height="24px" title="About">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path d="M14.59 2.59c-.38-.38-.89-.59-1.42-.59H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM15 18H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm0-4H9c-.55 0-1-.45-1-1s.45-1 1-1h6c.55 0 1 .45 1 1s-.45 1-1 1zm-2-6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"/>
                            </svg>
                        </Link>
                    </OverlayTrigger>

                    {/* API Page */}
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip>
                                The place where you can learn about programming with our API
                            </Tooltip>
                        }
                    >
                        <Link className="MenuButton"  to="/api">
                            <svg className="MenuIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={ApiColour} width="30px" height="30px" title="Insights.gg">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path d="M8.7 15.9L4.8 12l3.9-3.9c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0l-4.59 4.59c-.39.39-.39 1.02 0 1.41l4.59 4.6c.39.39 1.01.39 1.4 0 .39-.39.39-1.01 0-1.4zm6.6 0l3.9-3.9-3.9-3.9c-.39-.39-.39-1.01 0-1.4.39-.39 1.01-.39 1.4 0l4.59 4.59c.39.39.39 1.02 0 1.41l-4.59 4.6c-.39.39-1.01.39-1.4 0-.39-.39-.39-1.01 0-1.4z"/>
                            </svg>
                        </Link>
                    </OverlayTrigger>

                    {/* App Page */}
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip>
                                The place where you can convert your logs
                            </Tooltip>
                        }
                    >
                        <Link className="MenuButton"  to="/convert">
                            <svg className="MenuIcon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={AppColour} width="24px" height="24px">
                                <rect fill="none" height="24" width="24"/>
                                <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12.91,18.15 c-0.31,0.31-0.85,0.09-0.85-0.35v-0.8c-0.02,0-0.04,0-0.06,0c-1.28,0-2.56-0.49-3.54-1.46c-1.43-1.43-1.81-3.52-1.14-5.3 c0.19-0.51,0.86-0.64,1.24-0.25l0,0c0.22,0.22,0.27,0.54,0.17,0.82c-0.46,1.24-0.2,2.68,0.8,3.68c0.7,0.7,1.62,1.03,2.54,1.01v-0.94 c0-0.45,0.54-0.67,0.85-0.35l1.62,1.62c0.2,0.2,0.2,0.51,0,0.71L12.91,18.15z M15.44,14.02L15.44,14.02 c-0.22-0.22-0.27-0.54-0.17-0.82c0.46-1.24,0.2-2.68-0.8-3.68c-0.7-0.7-1.62-1.04-2.53-1.02c0,0,0,0,0,0v0.94 c0,0.45-0.54,0.67-0.85,0.35L9.46,8.18c-0.2-0.2-0.2-0.51,0-0.71l1.62-1.62c0.31-0.31,0.85-0.09,0.85,0.35v0.81 c1.3-0.02,2.61,0.45,3.6,1.45c1.43,1.43,1.81,3.52,1.14,5.3C16.48,14.28,15.82,14.41,15.44,14.02z"/>
                            </svg>

                        </Link>
                    </OverlayTrigger>
                </div>

                <hr className="MenuDivider"/>
                
                {/* Info Section */}
                <div className="MenuItemGroups">
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip>
                                The the discord where you can get help or chat
                            </Tooltip>
                        }
                    >
                    {/* Discord Invite */}
                    <div className="MenuButton" onClick={() => {window.open("https://discord.gg/THmHyAwWDN")}}>
                        <img className="DiscordLogo" src="/Discord-Logo-White.svg" alt="The discord logo"/>
                    </div>
                    </OverlayTrigger>

                    {/* Github Repo */}
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip>
                                The custom lobby code
                            </Tooltip>
                        }
                    >
                        <div className="MenuButton" onClick={() => {window.open("https://github.com/AudIsCool/Overwatch_Farmer")}}>
                            <svg className="DiscordLogo" height="30" viewBox="0 0 16 16" version="1.1" width="30" aria-hidden="true" fill="white">
                                <path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
                            </svg>
                        </div>
                    </OverlayTrigger>
    
                </div>
                
                {/* Hide At Bottom */}
                <div className="MenuButton AlertIcon">
                    <IssuesComponent/>
                </div>
            </div>
        )

    }


}
