/**
 * @name   FileComponent.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll allow users to see the status 
 *         of a file that they've uploaded
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';


//Stylings
import "../../../Stylings/InputComponents/FileComponent.css";


/**
 * @name UploadedFile
 *       This is the component that houses all of the 
 *       settings reguarding the information about the 
 *       game they just played
 */


export class UploadedFile extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Setup States
        this.state = {
            "Proccessed" : false,
            "Status"     : "Not uploaded",
            "Metadata"   : null,
            "Interval" : null
        }

        this.IntervalSet = false;

    }

    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */

    render(){
        //If we have new data but no interval check
        if(this.props.File.AnalysisId !== null && this.IntervalSet === false){
            //Set as true, then activate
            this.SetupInterval();
        }

        //Setup proccessed 
        let Status    = this.state.Status;
        let Processed = this.state.Proccessed ? ("Processed") : ("Processing")
        
        //If we haven't uploaded
        if(this.state.Status === "Not uploaded" && this.props.File.Uploaded === false){
            Processed = "--"
        }

        //If we uploaded
        if(this.state.Status === "Not uploaded" && this.props.File.Uploaded === true && this.props.File.AnalysisId === null){
            Status = "Uploaded";
        }

        //Render
        return(
            <div className="FileComponent">
                <div className="FileComponentName">{this.props.File.name}</div>
                <div>{Processed}</div>
                <div className="FileComponentStatus">Status: {Status}</div>
            </div>
        )

    }


    SetupInterval(){
        //Now check its status
        this.IntervalSet = true;

        //Set interval
        let Interval = setInterval(
            () => {this.PerformStatusCheck()},
            500 // Every 500 ms make request
        );

        //Save internal
        this.setState({"Interval" : Interval, "Status" : "Checking status of upload..."});
        
    }


    async PerformStatusCheck(){
        //Fetch data
        let AnalysisId = this.props.File.AnalysisId
        let LogId      = this.props.File.LogId

        let StatusCheck = await fetch("/get_log", {
            method: "POST",
            body: JSON.stringify({
                "AnalysisId" : AnalysisId,
                "LogId"      : LogId
            }),
            headers: {
                "Cache-Control": "no-cache", "Pragma": "no-cache",
                'Content-Type': 'application/json'
            }
        });

        //Check the upload generation 
        if(StatusCheck.ok !== true){
            //Fetch errors and display them 
            console.error(`Failed when getting the log of ${LogId} for ${AnalysisId}`)
            console.error(StatusCheck.resposne);

            
            window.clearInterval(this.state.Interval)
            this.setState({"Proccessed" : false, "Status" : "Failed fetching log info | Server crashed"})
            return
        }

        //Check resposne
        let StatusCheckJson = await StatusCheck.json();

        //If the creation failed
        if (StatusCheckJson.Success === false){
            //Fetch errors and display them 
            console.error(`Failed when getting the log of ${LogId} for ${AnalysisId}`)
            console.error(JSON.stringify(StatusCheckJson));

            window.clearInterval(this.state.Interval)
            this.setState({"Proccessed" : false, "Status" : "Failed fetching log info, check log"})
            return
        }


        //Check the data to see if it's finished processing
        let Check = StatusCheckJson.Data;

        //Before we check update the parent status 
        this.props.UpdateProcessData(this.props.Id, Check)

        //If finished without errors
        if(Check.Processed === true && Check.ProcessingInfo.ProcessingErrors === null){
            window.clearInterval(this.state.Interval)
            
            console.log(this.state.Interval)

            this.setState({"Proccessed" : true, "Status" : "Processing finished :)"});
            return
        }

        if(Check.Processed === false && Check.ProcessingInfo.ProcessingErrors !== null){
            window.clearInterval(this.state.Interval)
            
            this.setState({"Proccessed" : true, "Metadata" : Check, "Status" : Check.Status + " " + Check.ProcessingInfo.ProcessingErrors});
            return
        }

    }


}