/**
 * @name   ServerOutput.js
 * @author Aud#9488
 *         This file contains the main component that'll host all of the child
 *         components that make up the main app screen.
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card, Spinner, Button} from "react-bootstrap";

//Our Display Syste,
import {GameDataDisplay} from "./GameDisplayComponent.js";

//Import our styling
import "../../../Stylings/OutputComponents/ServerOutput.css"

/**
 * @name ServerOutput
 *       This is the component that houses all of the components 
 *       related to showing the server's output
 */


export class ServerOutput extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Setup state
        this.state = {
            "Page"     : "Fights",
            "GridData" : [[]],
            "DownloadLinks" : null,
            "FetchingDownloadLinks" : false
        }

    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        return(
            <Card className={this.props.className}>
                <Card.Header className="InputCardTitle">
                    <div className="InputCardTitleText">Server Output</div>
                </Card.Header>
               
                <Card.Body style={{margin:0, padding: 0}}>
                    {this.CurrentState()}
                </Card.Body>

                <Card.Footer style={{height: "62px"}}></Card.Footer>
            </Card>
        )

    }


    /**
     * @name ReturnCSV
     * @description Returns the CSV that the user needs- If we're loading 
     *              return a loading screen, but if we're not loading return 
     *              the CSV holders
     * 
     * @return {React.Component.Spinner || GameInfoDisplay} Returns the loading screen or the CSV display system
     *                                                      depending on the state of props.Loading                                 
     */
    ReturnCSV(){
        if(this.props.Loading === true){
            return this.ReturnLoading()
        }

        else{
            return this.ReturnOutput();
        }
    }

    /**
     * @name ReturnOutput
     * @description Returns the CSV output from the server, splitting it up
     *              into two pages: Fights and Events. We push this to a seperate function for
     * 
     * @return {GameDataDisplay} A screen that displays the CSVs cleanly
     */
    ReturnOutput(){

        return(
            <GameDataDisplay
                FightsCSV={this.props.Fights}
                EventsCSV={this.props.Events}
                ChangePage={(Page) => {this.setState({"Page" : Page})}}
                UpdateGridData={(GridData) => {this.setState({"GridData" : GridData})}}
            />
        )

    }


    /**
     * @name ReturnLoading 
     * @description Returns a loading screen to display
     * 
     * @return {React.Component.Spinner} A spinner to show loading
     */
    ReturnLoading(){
        return(<Spinner animation="grow" variant="success" />)
    }


    /**
     * @name CurrentState
     * @description Returns the current state of the upload progress
     */
    CurrentState(){
        //If nothing's going on 
        if(this.props.CurrentStage === null || this.props.CurrentStage === "PreUpload"){
            return(
                <div  className="InputCardUploadBody">
                    <h3>Upload your logs to get started</h3>
                    <div style={{color: "rgba(255, 255, 255, 0.5)"}}>You can generate logs by playing a scrim with our custom lobby <br/> Click <a href="/">here</a> to learn more! Or don't I'm not your boss.</div>
                </div>
            )
        }

        if(this.props.CurrentStage === "Upload"){
            return (
                <div  className="InputCardUploadBody">
                    <h3>{this.props.CurrentStage} Progress</h3>
                    <div style={{color: "rgba(255, 255, 255, 0.5)"}}>{this.props.UploadState}</div>
                </div>
            )
        }

        if(this.props.CurrentStage === "UploadFinished"){
            return (
                <div  className="InputCardUploadBody">
                    <h3 className="InputCardHeader">Upload Done :D</h3>
                    <div style={{color: "rgba(255, 255, 255, 0.5)"}}>You may now analyze</div>
                </div>
            )
        }

        //The main page
        if(this.props.CurrentStage === "Analysis"){
            return (
                <div className="InputCardUploadBody">
                    <h3 className="InputCardHeader">{this.props.CurrentStage} Progress</h3>
                    <div style={{color: "rgba(255, 255, 255, 0.5)"}}>{this.props.AnalysisState}</div>
                </div>
            )
        }

        
        if(this.props.CurrentStage === "AnalysisFinished"){
            return (
                <div className="InputCardUploadBody">
                    <h3 className="InputCardHeader">Analysis Done :D</h3>
                    <div>
                        {this.DownloadButtons()}
                    </div>
                </div>
            )
        }

    
    }



    DownloadButtons(){
        console.log(this.props.FightsUrl)
        console.log(this.props.EventsUrl)
        return(
            <div>
                <Button variant="success" className = "InputCardUploadButton" onClick={() => {window.open(this.props.FightsUrl)}}>Download Fights CSV</Button>
                <Button variant="success" className = "InputCardUploadButton" onClick={() => {window.open(this.props.EventsUrl)}}>Download Events CSV</Button>
            </div>
        )
    }



    /**
     * @name Download
     * @description Downloads the CSV blob to the user's machine by creating a link, 
     *              storing the CSV data in it and clicking on it
     */
    Download(){
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.props[this.state.Page]));
        element.setAttribute('download', `${this.props.GameDate}-${this.state.Page}.csv`);
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
    }

    /**
     * @name Copy
     * @description Copy's the CSV blob to the user's clipboard, by taking the grid data
     *              and pushing it into the clipboard. They can then take this and paste 
     *              it straight into excel
     */
    Copy(){
        //Setup final string to copy
        let GridConverted = ""

        //For each row in our grid
        for(var RowInts in this.state.GridData){
            //Setup the row
            let Row = ""
            
            //Go through each cell and add it onto our row
            for(var CellInts in this.state.GridData[RowInts]){
                let Cell = this.state.GridData[RowInts][CellInts];

                if(CellInts === 0){
                    Row += Cell.value;
                }

                else{
                    Row += `\t ${Cell.value}`;
                }
            }

            //Add our row to final string
            GridConverted += `${Row}\n`;
        }


        //Write the string to the user's clipboard
        navigator.clipboard.writeText(GridConverted)
            .then(
                //On Success
                () => {
                    this.props.AddToast("Conversion was added to your clipboard", {appearance: 'success', autoDismiss: true})
                },
                
                //On Error
                (Error) => {
                    this.props.AddToast(`Couldn't add conversion to your clipboard, error ${Error}`, {appearance: 'error', autoDismiss: false})

                }
            );
    }

}