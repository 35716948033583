/**
 * @name   ConfigurationModal.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll host the configuration settings
 *         that show up when the user wants to send 
 *         a parse request
**/

//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Modal, Card} from "react-bootstrap";

//Fetch our styling
import "../../../Stylings/InputComponents/ConfigurationModal.css";

//Import Sub Components
import {ConfigureGameInfo} from "./ConfigurationComponents/ConfigureGameInfo.js";
import {PageButton}        from "./ConfigurationComponents/PageButton.js";
import {ConfigureTeamInfo} from "./ConfigurationComponents/ConfigureTeamInfo.js";
import {SettingsList}      from "./ConfigurationComponents/SettingsComponent.js";

/**
 * @name ConfigurationModal
 *       This is the component that houses all of the 
 *       components related to setting up the final
 *       parse request
 */


export class ConfigurationModal extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Fetch Save functions
        this.ModifySettings = props.ModifySettings;

        //Fetch Settings
        this.Settings = this.props.Settings

        //Setup States
        this.state = {
            "Page"  : "Setup Game Information",
            "Pages" : [
                "Setup Game Information", "Configure your team", "Configure their team", "Configure parsing settings"
            ]
        }

    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render
        return(
            <div style={this.props.style}>
                <Modal 
                    dialogClassName = "ConfigurationModal"
                    style           = {{width: "100%", height: "100%"}}
                    show            = {this.props.Show} 
                    onHide          = {() => {this.props.SetVisibility(false)}}
                    centered
                >

                        <Card className="ConfigurationCard">
                            <Card.Header className="ConfigurationCardTitle">
                                {this.state.Page}
                            </Card.Header>

                            <Card.Body style={{width: "100%", height: "90%", padding: 0, display: "flex"}}>

                                <div className="PagesPane">
                                    {this.FetchPageButtons()}
                                </div>

                                <div className="ConfigurationPane">
                                    {this.CurrentPage()}
                                </div>

                            </Card.Body>

                            <Card.Footer className="ConfigurationCardFooter">                      
                                {this.DirectionalButtons()}
                            </Card.Footer>
                        </Card>

                </Modal>
            </div>
        )

    }


    CurrentPage(){
        //For Game Info
        if(this.state.Page === this.state.Pages[0]){
            return(
                <ConfigureGameInfo
                    Settings       = {this.props.Settings}
                    ModifySettings = {this.props.ModifySettings}
                />
            )
        }

        //For Your Team Config
        if(this.state.Page === this.state.Pages[1]){
            return(
                <ConfigureTeamInfo
                    Team             = {"Yours"}
                    PlayerList       = {this.props.PlayerList}
                    Settings         = {this.props.Settings}
                    ModifySettings   = {this.props.ModifySettings}
                    ModifyPlayerList = {this.props.ModifyPlayerList}
                />
            )
        }

        //For their team config
        if(this.state.Page === this.state.Pages[2]){
            return(
                <ConfigureTeamInfo
                    Team             = {"Theirs"}
                    PlayerList       = {this.props.PlayerList}
                    Settings         = {this.props.Settings}
                    ModifySettings   = {this.props.ModifySettings}
                    ModifyPlayerList = {this.props.ModifyPlayerList}
                />
            )
        }

        //For the final settings
        if(this.state.Page === this.state.Pages[3]){
            return(
                <SettingsList
                    Settings       = {this.props.Settings}
                    ModifySettings = {this.props.ModifySettings}
                />
            )
        }

    }

    FetchPageButtons(){
        let Buttons = []
        for(var Pages in this.state.Pages){
            let Page = this.state.Pages[Pages];

            Buttons.push(
                <PageButton
                    Page       = {this.state.Page}
                    PageName   = {Page}
                    ChangePage = {(Page) => {this.setState({"Page" : Page})}}
                    className  = "PageButton"
                />
                
            )
        }


        return Buttons
    }

    DirectionalButtons(){
        //Setup whether or not we can go forwards
        let BackDisabled = false;

        let FirstPage = this.state.Pages[0];
        let LastPage  = this.state.Pages[this.state.Pages.length - 1];
       
        //If we're on the first page we can't go back
        if(this.state.Page === FirstPage){
            BackDisabled = true;
        }

        //If we're on the last page we can't go forwards
        if(this.state.Page === LastPage){
            //return our buttons
            return(
                <div className="DirectionButtonDiv">
                    <Button
                        variant="outline-success"
                        disabled={BackDisabled}
                        className="DirectionButtons"
                        onClick={() => {this.ChangePage("Backwards")}}
                    >
                        Back
                    </Button>

                    <Button
                        variant="outline-success"
                        className="DirectionButtons"
                        onClick={() => {this.props.SendRequest();this.props.SetVisibility(false)}}
                    >
                        Done
                    </Button>
                </div>

            )
        }

        //return our buttons
        return(
            <div className="DirectionButtonDiv">
                <Button
                    variant="outline-success"
                    disabled={BackDisabled}
                    className="DirectionButtons"
                    onClick={() => {this.ChangePage("Backwards")}}
                >
                    Back
                </Button>

                <Button
                    variant="outline-success"
                    className="DirectionButtons"
                    onClick={() => {this.ChangePage("Forwards")}}
                >
                    Next
                </Button>
            </div>

        )

    }


    ChangePage(Direction){
        //Setup what direction we're going in
        let Change = 0;

        //Find out what direction we're going in
        if(Direction === "Forwards"){
            Change = 1;
        }

        if(Direction === "Backwards"){
            Change = -1;
        }

        //Find the next page
        let NextPage = this.state.Pages.indexOf(this.state.Page) + Change;

        //Go to the next page
        this.setState({"Page" : this.state.Pages[NextPage]})
    
    }


   
}
