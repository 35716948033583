/**
 * @name   PageButton.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll allow users to change what page 
 *         they're on specifically
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';


/**
 * @name PageButton
 *       it's a button...
 */


export class PageButton extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render
        let Style = {}

        if(this.props.Page === this.props.PageName){
            Style = {
                "backgroundColor" : "#3E454D"
            }
        }

        return(
            <div 
                onClick={() => {this.props.ChangePage(this.props.PageName)}}
                className={this.props.className} 
                style={Style}
            >
                <div style={{width: "90%", height: "100%", marginLeft: "5%" }}>
                    {this.props.PageName}
                </div>
            </div>
        )

    }


}