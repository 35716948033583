/**
 * @name   ConfigureGameInfo.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll allow users to change info 
 *         related to the game that they're parsing
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormControl} from "react-bootstrap";

//Stylings
import "../../../../Stylings/InputComponents/ConfigureGameInfo.css";


/**
 * @name ConfigureGameInfo
 *       This is the component that houses all of the 
 *       settings reguarding the information about the 
 *       game they just played
 */


export class ConfigureGameInfo extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Fetch Save functions
        this.ModifySettings = props.ModifySettings;

        //Fetch Settings
        this.Settings = this.props.Settings

        //Setup States
        this.state = {
            "GameName"      : this.props.Settings.GameName,
            "GameDate"      : this.props.Settings.GameDate,
            "YourTeamName"  : this.props.Settings.YourTeam,
            "TheirTeamName" : this.props.Settings.TheirTeam
        }

    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render
        return(
            <div className="GameInfoDiv">
                <h3 className="GameInfoPageTitle"> Game Information </h3>

                {/* Scrim Name */}
                <div className="InputBox">
                    <h6 className="GameDataTitle">Name of Scrim</h6>
                    <FormControl
                        className   = "GameDataInput"
                        placeholder = {this.props.Settings.GameName}
                        onChange    = {(Target) => {this.props.ModifySettings("GameName", Target.target.value)}}
                    />
                </div>    

                {/* Scrim Date */}
                <div className="InputBox">
                    <h6 className="GameDataTitle">Date of Scrim</h6>
                    <FormControl
                        className   = "GameDataInput"
                        placeholder = {this.props.Settings.GameDate}
                        onChange    = {(Target) => {this.props.ModifySettings("GameDate", Target.target.value)}}

                    />
                </div>  


                {/* Name of their team */}
                <div className="InputBox">
                    <h6 className="GameDataTitle">Name of your team</h6>
                    <FormControl
                        className   = "GameDataInput"
                        placeholder = {this.props.Settings.YourTeam}
                        onChange    = {(Target) => {this.props.ModifySettings("YourTeam", Target.target.value)}}

                    />
                </div>  


                {/* Name of the other team */}
                <div className="InputBox">
                    <h6 className="GameDataTitle">Name of your scrim partner</h6>
                    <FormControl
                        className   = "GameDataInput"
                        placeholder = {this.props.Settings.TheirTeam}
                        onChange    = {(Target) => {this.props.ModifySettings("TheirTeam", Target.target.value)}}

                    />
                </div>  



            </div>
        )

    }


}