/**
 * @name   InfoText.js
 * @author Aud#9488
 *         This file contains the raw text
 *         that will be displayed to the user at the end of the day
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";

//Styling
import "../../../Stylings/AboutComponents/About.css";

/**
 * @name InfoText
 *       This is the component that contains  
 *       some fucking text
 */


export class InfoText extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);
   
        //Props
        this.props = props;
    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Render
        return(
            <div className={this.props.className}>
                    <h1 className="TextDivTitle">The Overwatch Farmer <br/> Project</h1>

                    <div id="IntroSection" className="IntroSection">
                        <div className="IntroSectionBegining">
                            <div>
                                <h2 className="Headers">About</h2>
                                <hr className="HeaderLineDivider"/>
                                The Overwatch Workshop Farmer is a program created by <a target="__blank" href="https://twitter.com/MrCoachAud" rel="noreferrer">Aud</a> from <a target="__blank" href="https://insights.gg/About" rel="noreferrer">Insights.gg</a> designed to parse logs from Aud's Overwatch Workshop Farmer custom lobby code.
                                This program was written as a prototype / experiment for what could be done with the Insights.gg Pro platform in the future regarding Overwatch; therefore it may be incredibly buggy / crash often. 
                                If it does you can bug Aud on discord (Aud#9488) or at <a target="__blank"  href="mailto:aud@insights.gg">aud@insights.gg</a>.
                                If you'd like to learn more about Insights and the tools they provide to esports professionals either click their logo on the left, the link I supplied earlier, or if you're lazy (god knows I am) <a target="__blank"  href="https://insights.gg/About" rel="noreferrer">click here</a>. 
                                What this also means is that the program may never be finished, or changed entirely on a whim, a side effect of this project being an prototype / experiment.
                            </div>
                            
                        </div>

                        <div className="ParagraphBlock">
                            That being said this program collects stats such as 
                            
                            <ul>
                                <li>Kills</li>
                                <li>Deaths</li>
                                <li>Ults casted</li>
                                <li>Key abilities casted</li>
                                <li>Damage done</li>
                                <li>Healing done done</li>
                            </ul>

                            from the Overwatch Custom Game Lobby system, by using a internal function called Log To Inspector. This function allows us to log anything within the game, to a console, and therefore a file- 
                            From there we take these files and parse them into data that we can perform analyses on. On this platform we allow you to upload those logs provided by the Overwatch Custom Game Lobby, parse them and 
                            aggregate them into a fight based system, where in which a fight is a period of time where in which two teams have committed resources trying to either defend or attack an objective. 
                        </div>

                        <div className="ParagraphBlock">
                            Through this fight based system, beginner analysts can begin to make conclusions on the success rate of comps being ran, players being played, etc-
                            Hence the time invested in providing the fight based system to users. Higher level analysts will simply take the logs provided by the console and make their conclusions about the data, without the use of this web platform, which is greatly encouraged. 
                        </div>

                    </div>

                    <div id="HowToSection"   className="HowToSection TextBlock">
                        <h2 className="Headers">Getting started</h2>
                        <hr className="HeaderLineDivider"/>

                        The process for using the platform is relatively simple: You'll first need to play a game of Overwatch using our custom lobby code (which can be found on the right), and upload those 
                        logs generated by the custom lobby to this site for parsation. You can see a video of this process (with an older version) <a href="https://cdn.discordapp.com/attachments/454878905900335124/802317126945734666/2021-01-22_17-19-37.mp4" target="__blank" rel="noreferrer">here</a>.
                        
                        <h4 className="InstructionHeader">Getting the logs</h4>
                        <ul>
                            <li className="FirstInstruction">First open up the Overwatch Client (duh lmao)</li>
                            <li className="Instruction">If this is your first time using the platform go to [your settings -&gt;  game play -&gt;  Enable Workshop Inspector] and turn on the workshop inspector feature <b>AND INSPECTOR LOG FILE</b>. This is what allows you to see the data logged out, and have it auto saved to a file <img className="InstructionImage" src="/OWF_Instructions_TurnonInspector.jpg" alt="Turning on the inspector"/></li>
                            <li className="Instruction">Then create a custom lobby using the lobby code provided above </li>
                            <li className="Instruction">Then run your scrims like usual... That's it!! OH! DO NOT, I REPEAT DO NOT OPEN THE INSPECTOR WHILE THE GAME IS IN PROGRESS UNLESS YOU WANT TO CRASH. (Blame magzie hehe)</li>
                        </ul>

                        
                        <h4 className="InstructionHeader">Parsing the logs</h4>
                        <ul>
                            <li className="FirstInstruction">By default all of your logs are saved in <code>C:\Users\YOUR_USERNAME_GOES_HERE\Documents\Overwatch\Workshop</code>, where <code>YOUR_USERNAME_GOES_HERE</code> is your windows username. For example mine is Aud, so my file location looks like <code>C:\Users\Aud\Documents\Overwatch\Workshop</code></li>
                            <li className="Instruction">When you open that folder you should see a list of files like this (given you've played a game in the custom lobby, if not the files won't be there lol) NOTE I highly suggest ordering by <code>Date Modified</code> so you can tell which logs are which!!<img className="InstructionImage" src="/OWF_Instructions_FileLocation.jpg" alt="List of files"/></li>
                            <li className="Instruction">Navigate to the <Link to="/convert">"Convert"</Link> section of the webapp in another tab of your browser, and upload them by clicking the upload button and selecting the files you wish to upload. You can also drag and drop your files into the Workshop Input card. <img className="InstructionImage" src="/OWF_Instructions_UploadPage.jpg" alt="Uploading files"/></li>
                            <li className="Instruction">Once you have selected your files, you can hit that big green upload button and get to uploading your data for analysis :D.   <img className="InstructionImage" src="/OWF_Instructions_UploadFiles.jpg" alt="Upload"/></li>
                            <li className="Instruction">Once your files have been uploaded and processed, you can analyze the file by clicking on the analysis button. This will take you to the analysis configuration page wherein which you'll select your players and other various settings for the analysis <img className="InstructionImage" src="/OWF_Instructions_AnalyzeFiles.jpg" alt="Click analyze"/><img className="InstructionImage" src="/OWF_Instructions_AnalysisSettings.jpg" alt="Setting up the analysis"/></li>
                            <li className="Instruction">After clicking the through the final menu, you'll be able to begin the analysis!! After some time it should finish, and you'll be able to download the files!! Don't worry, better systems of getting the data are coming but due to constraints with my health and work life I didn't have time to get those features out- They'll be out in the future though :) <img className="InstructionImage" src="/OWF_Instructions_FinalUpload.jpg" alt="the final upload"/></li>
                           
                        </ul>

                        <h4  className="InstructionHeader">So uh... now what?</h4>
                        <div>
                            Well uh... idk, the data is all yours! What you choose to do with it is up to you :)
                            You can copy / download the CSVs exported and push them into Google Sheets or Microsoft Excel and start calculating numbers
                            like some weird desk jockey if you'd really like :D. If you're a "software engineer" or "programmer" or "basement dweller who makes computers do neat things"
                            go check out my API documentation!!! If you'd like to automate the process of uploading your logs to the OWF platform for analysis you can easily do that by 
                            making simple http post requests to my end points!
                        </div>
                    </div>

                    <div id="HowItWorks"     className="WorkItWorksSection TextBlock">
                        <h2 className="Headers">How it works</h2>   
                        <hr className="HeaderLineDivider"/>
                        <div>
                            
                            <h4 className="SubHeader" style={{margin: 0}}>The introduction of Log to Inspector</h4>
                            <div>
                                On November the 17th of 2020, the Overwatch client got an update to its workshop system, that introduced a new feature called "Log To Inspector". Marketed as a way to debug custom games,
                                it allows a custom game to log any value to an internal log collecter, and copy those logs to the clipboard so that they may be pasted to a file and reviewed.
                            </div>


                            <h4 className="SubHeader">TL;DR</h4>
                            <div>
                                In short, we use this function to log out internal values in a concept we call "events". We then take those events and parse / aggregate them on this site, to derive fight base statistics.
                            </div>


                            <h4 className="SubHeader">A long explanation</h4>
                            <div>
                                In long, when something occurs within an Overwatch game (a kill, ultimate cast, ability cast), we log that occurrence to the console in a format that is both understood by the user, and understood by a computer. 
                                But just raw events overtime doesn't tell us a whole lot about the game- I suppose you could go through and count all the times that Direwolf inted his brains out but that doesn't tell you a whole lot beyond the fact that he's bad, 
                                and it's data without context- Data existing for the sake of existing. 
                            </div>

                            <div className="ParagraphBlock">
                                See a more useful stat would be, how often Direwolf ints when he's on Lucio on Kings Row. But you can't easily get that from a simple list of events, because events are just moments in time, with no real relation to each other. 
                                So we need to build those relationships, and we do so by creating a list of every fight that has occurred within a given round of Overwatch, and sort the events into those fights. Through those now sorted events we can now infer 
                                what map is being played, who's playing and what hero they're on. Then we can start to count the number of times that a player on [X] hero has died on [X] map by looking at the death events tied to a specific map. 
                            </div>

                            <div className="ParagraphBlock">
                                Which not only allows us to answer the question before, but allows us to dig further into the context of these deaths and begin to create hypotheses as to Direwolf's inting. We could look at who else is dying in the fights, and 
                                at what point in time they're dying compared to Direwolf, and find out that he's the last person to die consistently when he's on Lucio on Kings, which may let us know that hey maybe Direwolf isn't actually inting at all. 
                            </div>

                            <div className="ParagraphBlock">
                                Which is something you could never conclude sheerly based on the number of death events that the poor sod had on Lucio. So in order to allow you to achieve such understandings, we've created this website, which not only can convert these
                                Event Logs exported from Custom Games to CSV (Allowing you poor spreadsheet jockies to begin analyzing data), but also converts these logs into a fight based context.
                            </div>

                            <div className="ParagraphBlock">
                                <h4 className="SubHeader">But more specifically how do we define fights?</h4>
                                In our case, a fight is defined as a period of time where in which two teams invest resources (cool downs is the easiest example) to achieve progress at either defending space, or taking space. 
                                But in order to sort our data into a fight based context for better understanding, we have to find out when those fights are occuring... so how do we go about that?
                            </div>
                            
                            <div className="ParagraphBlock">
                                Well we use a DBSCAN Clustering algorithm to determine periods where in which events are clustered together, those periods of time are then marked as fights. At high levels of play, 
                                teammates group up and perform solid pushes as a unit, creating space of time where in which large conflicts are occuring, and periods of time where in which no conflict is occuring. This makes this algorithm the most optimal 
                                method of finding fights, however as Jack always yells at me about, there are better methods of finding fights, that involve looking at the derivative of global healing done and damage done, but in the Workshop's current state
                                such algorithms are impossible to implement- However changes are on the horizon, and both Leguminote (the author of the fight detection algorithim) and I will pursue those methods of fight detection when we have the capacity to do so 
                                (probably 1 - 2 months after Beta release c:)
                            </div>

                        </div>


                    </div>

                    <div id="CreditsSection" className="CreditsSection TextBlock">
                        <h2 className="Headers">Credits</h2>
 
                        <hr className="HeaderLineDivider"/>
 
                        <div>
                            <div className="ParagraphBlock">
                                First off thank you to everyone who's contributed thus this far!!! Whether it be through discussions we've had, ideas you've sent
                                or just straight up algorithms. I in no way, shape or form, could have gotten this far without your help- Hence why I rely 
                                on your feedback and thoughts heavily. I'm just one man I cannot do it all alone!!
                            </div>

                            <div className="ParagraphBlock">
                                But specifically thank you to

                                <ul>
                                    <li><a href="https://twitter.com/A_D1R3W0LF" rel="noreferrer">Mizzou's Direwolf</a>, who showed me the patch notes teasing the release of the Log To Inspector tool, and our constant discussions on the subject as well discussions about other things.</li>
                                    <li><a href="https://twitter.com/Jack_di_Quadri" rel="noreferrer">Former HSL's Jack</a>, who helped write the early alpha lobby code and provided constant feedback for the direction of the project and its founding principles</li>
                                    <li><a href="https://twitter.com/Sonofa_Blitch" rel="noreferrer">tOSU's Leguminote</a>, who originally wrote the fight detection algorithm and has been helping me build the core ideas of this project ever since the closed alpha release</li>
                                    <li><a href="https://twitter.com/CoachSeabo" rel="noreferrer">GZ Charge's Seabo</a>, who's given me some insight to what analytics look like in OWL, as well as critical feature / design requests that help make this project greater than it was</li>
                                    <li><a href="https://twitter.com/Tschoschi90" rel="noreferrer">HSL's Tschoschi</a>, who not only started me on the path of OW analytics, and my career at Insights, but for providing wisdom and thoughtful feedback on core ideas for the project</li>
                                    <li><a href="https://twitter.com/cyru5cuenca" rel="noreferrer">Siege.gg's Jebb</a>, for help with design concepts because I am not a very good designer (this webapp is proof pepelauf)</li>
                                    <li><a href="https://twitter.com/navsheik" rel="noreferrer">UCLA's Nav</a>, for keeping me company during my late night work sessions and helping me ensure the webapp doesn't crash constantly</li>
                                </ul>

                                and all of the members of the Aud Inc server during the closed alpha's runtime who brought AMAZING ideas to the table, and shedded light on how they're looking to use the data in the future, (yeah I'm looking at you 
                                    <a href="https://twitter.com/ProvenFrogMan" rel="noreferrer"> Proven</a> the meme man with never ending ideas,
                                    <a href="https://twitter.com/eventhorizon_ow" rel="noreferrer"> Event</a> <i>who can't seem to understand the concept of sending one message at a time c; </i>,
                                    <a href="https://twitter.com/Obasill_" rel="noreferrer"> Obasill</a> who occasionally chimed in both in discord and twitch chat to give another view point on matters, providing us extra wisdom and information when we needed it most, and
                                    <a href="https://twitter.com/phooy1" rel="noreferrer"> Shyam</a> for a detailed look into his thought process on the platform and project as a whole
                                )

                            </div>

                            <div className="ParagraphBlock">
                                And of course to the 20 teams from T2, T3 and collegiate who sat through my piss poor alpha stage, thank you so so so so much for not only sitting by while I hot fixed hundreds of bugs, but for even being willing to let me test my work with you. 
                            </div>

                            <div className="ParagraphBlock" style={{paddingBottom: "50px"}}>
                                Annnnnnnnnnnnnnnnnnnnnnnnnd finally the Insights.gg team- Without them, not only would this project be possible, but I wouldn't be here today. Steve, Stephen, Kevin and Jim have helped me grow so much in the last year with them, and I cannot thank them 
                                enough in the slightest for the wisdom they've bestowed upon me. I was gonna say something nice about Phillip but he keeps inting our Apex games so he doesn't get any D:&#60;
                            </div>


                        </div>

                   </div>
                </div>
        )

    }


}