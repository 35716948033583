/**
 * @name   ConfigureTeamInfo.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll allow users to change info 
 *         related to the teams that played within 
 *         their scrim
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Card} from "react-bootstrap";

//Our Styling
import "../../../../Stylings/InputComponents/ConfigureTeamInfo.css";

/**
 * @name ConfigureTeamInfo
 *       This allows users to configure a team, whether it be 
 *       theirs or a different team
 */


export class ConfigureTeamInfo extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Fetch Save functions
        this.ModifySettings = props.ModifySettings;

        //Fetch Settings
        this.Settings = this.props.Settings;

        //Setup player pool
        this.PlayerPool = this.props.PlayerList;
    }


    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Fetch Team Info
        let CurrentTeamName = "";

        if(this.props.Team === "Yours"){
            CurrentTeamName = this.props.Settings.YourTeam;
        }

        if(this.props.Team === "Theirs"){
            CurrentTeamName = this.props.Settings.TheirTeam;
        }

        //Render   
        return(
            <div className="ConfigureTeamInfoContainer">
                <h3 className="ConfigureTeamInfoTitle">Configuring Team: {CurrentTeamName}</h3>

                <h6 className="ConfigureTeamInfoHeaderText">This Team's Players</h6>
                <Card className="GlobalPlayersContainer">
                    {this.ListCurrentRoster()}
                </Card>


                <h6 className="ConfigureTeamInfoHeaderText">Players without a team</h6>
                <Card className="GlobalPlayersContainer">
                    {this.ListGlobalPlayers()}
                </Card>
            </div>
        )

    }


    ListGlobalPlayers(){
        let Players = []

        for(var PlayerInts in this.PlayerPool){
            Players.push(
                <Button 
                    id={this.PlayerPool[PlayerInts]}
                    onClick={(Event) => {this.AddPlayerToTeam(Event.target.id)}}
                    style={{width: "20%", height: "auto", margin: "2%"}}
                >
                    {this.PlayerPool[PlayerInts]}
                </Button>
            )
        }


        return Players

    }

    ListCurrentRoster(){
        //Select Roster
        let TeamList = [];
        
        if(this.props.Team === "Yours"){
            TeamList = this.props.Settings.YourRoster;
        }else{
            TeamList = this.props.Settings.TheirRoster;
        }


        //Create return div
        let ReturnDiv = [];

        //Create Buttons
        for(var PlayerInts in TeamList){
            let Player = TeamList[PlayerInts];

            ReturnDiv.push(
                <Button
                    id = {Player}
                    onClick = {(Event) => {this.RemovePlayerFromTeam(Event.target.id)}}
                    style={{width: "20%", height: "auto", margin: "2%"}}
                >
                    {Player}
                </Button>

            );
        }

        //Return buttons
        return ReturnDiv
    }


    AddPlayerToTeam(Key){
        //Setup Current Teams
        let TeamList        = [];

        //Select the team roster we're using atm
        if(this.props.Team === "Yours"){
            TeamList = this.props.Settings.YourRoster;
        }else{
            TeamList = this.props.Settings.TheirRoster;
        }

        //Setup new player pool
        let NewPlayerList   = [];

        //Fetch Player
        for(var PlayerInts in this.PlayerPool){
            let Player = this.PlayerPool[PlayerInts];

            //Append player
            if(Player === Key){
                TeamList.push(Player)
            }

            //Add to new global list
            else{
                NewPlayerList.push(Player);
            }
        }


        //Save changes
        this.PlayerPool = NewPlayerList;

        this.props.ModifyPlayerList(this.PlayerPool);
       
        if(this.props.Team === "Yours"){
            this.props.ModifySettings("YourRoster", TeamList);
        }else{
            this.props.ModifySettings("TheirRoster", TeamList);
        }

    }

    RemovePlayerFromTeam(Key){
        //Setup Current Teams
        let TeamList        = [];

        //Select the team roster we're using atm
        if(this.props.Team === "Yours"){
            TeamList = this.props.Settings.YourRoster;
        }else{
            TeamList = this.props.Settings.TheirRoster;
        }

        //Setup new player pool
        let NewPlayerList   = this.PlayerPool;
        let NewTeamList     = [];
        //Fetch Player
        for(var PlayerInts in TeamList){
            let Player = TeamList[PlayerInts];

        
            //Append player
            if(Player === Key){
                NewPlayerList.push(Player)
            }

            //Add to the team
            if(Player !== Key){
                NewTeamList.push(Player);
            }
        }



        //Save changes
        this.PlayerPool = NewPlayerList;
        this.props.ModifyPlayerList(this.PlayerPool);

       
        if(this.props.Team === "Yours"){
            this.props.ModifySettings("YourRoster", NewTeamList);
        }else{
            this.props.ModifySettings("TheirRoster", NewTeamList);
        }



    }

}