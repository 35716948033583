/**
 * @name   SheetsDisplay.js
 * @author Aud#9488
 *         This file contains the react component
 *         that'll display the game information in 
 *         an excel like format
 */


//Import React Components
import React from 'react';

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//For the editor
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import "../../../Stylings/OutputComponents/ServerOutputSpreadsheet.css"



/**
 * @name SheetsDisplay
 *       This is the component that houses all of the 
 *       components related controlling the settings 
 *       of the workshop conversion 
 */


export class SheetsDisplay extends React.Component{
    /**
     * Constructor, super and setup the refereces to state
     */
    constructor(props){
        //Super
        super(props);

        //Save props
        this.props = props

        //Create state
        this.state = {
            "CSV" : this.props.CSV,
            "Grid" : this.ConvertCSVToGrid(this.props.CSV)

        }

        //Propogate Grid Data
        this.props.UpdateGridData(this.state.Grid);

    }



    /**
     * DEFAULT REACT FUNCTIONS
     *        The following are overwritten functions passed down
     *        from React.Component
     */
    render(){
        //Setup grid handler 
        let Grid = this.state.Grid;

        //If we got new CSV convert it 
        if(this.props.CSV !== this.state.CSV){
            //Setup current Grid
            let Grid = this.ConvertCSVToGrid(this.props.CSV);

            //Update the internals
            this.setState({"Grid" : Grid, "CSV" : this.props.CSV});
            
            //Tell Parents about new grid data
            this.props.UpdateGridData(Grid);
        
        }

        //Send back component
        return(
            <div style={this.props.style}>
                <ReactDataSheet
                    Id="DataSheet"
                    data={Grid}
                    valueRenderer={cell => cell.value}
                    onCellsChanged={(changes) => {
                        changes.forEach(({ cell, row, col, value }) => {
                            Grid[row][col] = { ...Grid[row][col], value };
                        });

                        this.setState({"Grid" : Grid });

                        this.props.UpdateGridData(Grid);
                        }
                    }
                
                />

            </div>
        )

    }


    /**
     * @name ConvertCSVToGrid
     * @description It's as the name describes, takes the CSV that we get back from the server
     *              and converts it to a 2d array that our spreadsheet component can use 
     *              to display the data
     * 
     * @param {*} CSV The CSV returned from API during a parsation attempt
     * 
     * @return {[[{"value" : value}, ...], ...]} A 2D array that contains values to be displayed
     */
    ConvertCSVToGrid(CSV){
        if(CSV === null){
            return [[]]
        }

        let CSVLines = CSV.split("\n");

        let EventsGrid = []
        for(var Lines in CSVLines){
            let Row = []
            let Line = CSVLines[Lines];

            let Cells = Line.split(",");
            
            for(var CellEntries in Cells){
                let Cell = Cells[CellEntries];

                if(Lines === 0){
                    Row.push({
                        "value" : Cell,
                        "readOnly" : true
                    });
                }
                else{
                    Row.push({
                        "value" : Cell
                    });
                }

            }

            EventsGrid.push(Row);
        }

        //Clean 
        console.log(EventsGrid[0][0])


        return EventsGrid;
    }


}